import { pageRouter } from '@model/pageModel';

class AuthVm {
  get changeHeight() {
    const pathName = pageRouter.location.pathname || pageRouter.location?.location?.pathname;

    return pathName === '/auth/additionalInformation' || pathName === '/auth/invitedMembership';
  }
}

export default new AuthVm();
