import { useObserver } from 'mobx-react';
import vm from './VerificationPhoneNumberVm';
import st from './VerificationPhoneNumber.module.scss';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import { useEffect, useState } from 'react';

interface VerificationPhoneNumberProps {
  name: string;
  phoneNumber: string;
}

function VerificationPhoneNumber(props: VerificationPhoneNumberProps) {
  return useObserver(() => (
    <form onSubmit={(e) => vm.submit(e)} className={st.form}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logo_standby.svg?1" />
        </a>
      </div>
      <div className={st.mainTitle}>
        <em>문자로 받으신 인증번호</em>를 입력해 주세요.
      </div>
      <div className={st.info}>
        혹시 입력하신 전화번호가 정확하나 문자가 오지 않았다면,
        <br />
        스팸 문자함을 확인해 주세요.
      </div>
      <div className={st.inputs}>
        <InputText
          name="phoneNumber"
          explain="발송한 전화번호"
          value={props.phoneNumber}
          className={st.inputText}
          fullSize
          disabled
        />
        <InputText
          maxLength={10}
          name="verificationCode"
          explain="인증번호"
          className={st.inputText}
          value={vm.state.verificationCode}
          onChange={(e) => vm.setVerificationCode(e)}
          fullSize
          errorText={
            <Error
              name="verificationCode"
              value={vm.state.verificationCode}
              errorCase={{
                required: '인증번호를 입력해 주세요.',
              }}
            />
          }
        />
        <Timer />
      </div>
      <SquareBtn type="submit" className={st.squareBtn}>
        아이디 확인하기
      </SquareBtn>
      <div className={st.likeBtnBox}>
        <button type="button" onClick={vm.reload} className={st.likeBtn}>
          인증번호 재요청하기
        </button>
      </div>
    </form>
  ));
}

const Timer = () => {
  const MINUTES_IN_MS = 10 * 60 * 1000 - 1;
  const INTERVAL = 1000;
  const [timeLeft, setTimeLeft] = useState<number>(MINUTES_IN_MS);

  const minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(2, '0');
  const second = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, '0');

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - INTERVAL);
    }, INTERVAL);

    if (timeLeft <= 0) {
      clearInterval(timer);
      console.log('타이머가 종료되었습니다.');
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  return (
    <div className={st.timer}>
      {minutes === '-1' || second === '-1' ? (
        <>00분 00초</>
      ) : (
        <>
          {minutes}분 {second}초
        </>
      )}
    </div>
  );
};

export default VerificationPhoneNumber;
