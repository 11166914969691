import { useObserver } from 'mobx-react';
import SetupNewPasswordVm from './SetupNewPasswordVm';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import st from './SetupNewPassword.module.scss';
import clsx from 'clsx';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

function SetupNewPassword(props) {
  let location = useLocation();
  const token = queryString.parse(location.search).token;

  return useObserver(() => (
    <form onSubmit={(e) => SetupNewPasswordVm.submit(e, token)} className={st.setupNewPasswordContent}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logo_standby.svg?1" />
        </a>
      </div>
      <div className={st.mainTitle}>새로운 비밀번호를 설정해주세요</div>
      <div className={st.passwordBox}>
        <div className={st.title}>새 비밀번호</div>
        <InputText
          name="password"
          type="password"
          className={st.inputText}
          value={SetupNewPasswordVm.password}
          onChange={SetupNewPasswordVm.setPassword}
          fullSize
          errorText={
            <Error
              name="password"
              value={SetupNewPasswordVm.password}
              errorCase={{
                required: '비밀번호를 입력해 주세요.',
                pattern: {
                  value:
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                      SetupNewPasswordVm.password,
                    ),
                  message: '올바른 비밀번호 형식으로 입력해 주세요.',
                },
              }}
            />
          }
        />
        <div className={st.passwordAlert}>
          <div>8글자 이상 입력</div>
          <div>영문, 숫자, 특수문자 각 1개 이상 사용</div>
        </div>
      </div>
      <div className={st.newPasswordBox}>
        <div className={st.title}>새 비밀번호 확인</div>
        <InputText
          name="passwordCheck"
          type="password"
          className={st.inputText}
          value={SetupNewPasswordVm.passwordCheck}
          onChange={SetupNewPasswordVm.setPasswordCheck}
          fullSize
          errorText={
            <Error
              name="passwordCheck"
              value={SetupNewPasswordVm.passwordCheck}
              errorCase={{
                required: '비밀번호 확인을 입력해 주세요.',
                validate: {
                  func: SetupNewPasswordVm.isSame(),
                  message: '동일한 비밀번호를 입력해주세요.',
                },
              }}
            />
          }
        />
      </div>
      <SquareBtn type="submit" className={st.squareBtn}>
        비밀번호 재설정하기
      </SquareBtn>
    </form>
  ));
}
export default SetupNewPassword;
