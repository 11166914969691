import { useObserver } from 'mobx-react';
import vm from './FindIdVm';
import st from './FindId.module.scss';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import { useEffect } from 'react';
import VerificationPhoneNumber from './VerificationPhoneNumber/VerificationPhoneNumber';
import IdList from './IdList/IdList';

function FindId() {
  useEffect(() => {
    vm.verificationToken();
  }, []);
  return useObserver(() => {
    if (vm.step === 'findId') {
      return (
        <form onSubmit={vm.submit} className={st.form}>
          <div className={st.logoWrap}>
            <a href="https://standby.kr/" className={st.logo}>
              <img src="/images/logo_standby.svg?1" />
            </a>
          </div>
          <div className={st.mainTitle}>
            스탠바이에 가입된 <em>이름</em>과 <em>전화번호</em>를 입력하세요.
          </div>
          <div className={st.info}>
            보안을 위해 가입하신 전화번호로 인증문자를 보내드려요.
            <br />
            본인인증 후 아이디(이메일)을 안내해 드릴게요.
          </div>
          <div className={st.inputs}>
            <InputText
              name="name"
              explain="이름"
              className={st.inputText}
              placeholder="홍길동"
              value={vm.name}
              onChange={vm.setName}
              fullSize
              errorText={
                <Error
                  name="name"
                  value={vm.name}
                  errorCase={{
                    required: '이름을 입력해 주세요.',
                    pattern: {
                      value: regExp.onlyKorEng().test(vm.name),
                      message: '한글 또는 영문으로 입력해 주세요.',
                    },
                  }}
                />
              }
            />
            <InputText
              name="phoneNumber"
              explain="전화번호"
              className={st.inputText}
              placeholder="01012345678"
              value={vm.phoneNumber}
              onChange={vm.setPhoneNumber}
              fullSize
              errorText={
                <Error
                  name="phoneNumber"
                  value={vm.phoneNumber}
                  errorCase={{
                    required: '전화번호를 입력해 주세요.',
                    pattern: {
                      value: regExp.phoneNumber().test(vm.phoneNumber),
                      message: '올바른 전화번호를 입력해 주세요.',
                    },
                  }}
                />
              }
            />
          </div>
          <SquareBtn type="submit" className={st.squareBtn}>
            인증문자 보내기
          </SquareBtn>
        </form>
      );
    }
    if (vm.step === 'verificationPhoneNumber') {
      return <VerificationPhoneNumber phoneNumber={vm.phoneNumber} name={vm.name} />;
    }
    if (vm.step === 'idList') {
      return <IdList />;
    }
  });
}
export default FindId;
