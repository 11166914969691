import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import AdminAddModel from '@model/Corp/AdminAdd/AdminAddModel';
import { _alert, _confirm } from '@model/dialogModel';

class AdminAddService {
  async load() {
    const res = await get(`/userAuthority/company/${corpModel.id}/menu`);
    AdminAddModel.setAuthorities(res.data.authorities);
  }
  async invite(param) {
    param.authorities = [...AdminAddModel.addData.authorities.acct, ...AdminAddModel.addData.authorities.law].map(
      (authority) => {
        return {
          menuCode: authority.menuCode,
          useYn: authority.useYn,
        };
      },
    );

    await post(`userAuthority/company/${corpModel.id}/invite`, param);
    await _alert('관리자 초대를 발송했어요.');
    window.location.href = `/corp/adminSetting?corp=${corpModel.id}`;
  }
}
export default new AdminAddService();
