import { useObserver } from 'mobx-react';
import JoinMembershipVm from './JoinMembershipVm';
import st from './JoinMembership.module.scss';
import clsx from 'clsx';
import regExp from '@common/module/regExp';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import TextBtn from '@standby/common-ui/components/atoms/Button/TextBtn/TextBtn';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';
import { useEffect } from 'react';
import { Route, withRouter, Link } from 'react-router-dom';

function JoinMembership(props) {
  useEffect(() => {
    JoinMembershipVm.loadTerms();
  }, []);

  return useObserver(() => (
    <form onSubmit={(e) => JoinMembershipVm.submit(e)} className={st.joinMembershipContent}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logo_standby.svg?1" />
        </a>
      </div>
      <div className={st.mainTitle}>
        법인관리 <span className={st.bold}>스탠바이 헬프미 회원가입</span>
      </div>
      <div className={st.emailBox}>
        <div className={st.title}>
          이메일<span className={st.required}>*</span>
        </div>
        <InputText
          name="email"
          className={st.inputText}
          placeholder="example@logologo.com"
          value={JoinMembershipVm.email}
          onChange={JoinMembershipVm.setEmail}
          fullSize
          errorText={
            <Error
              name="email"
              value={JoinMembershipVm.email}
              errorCase={{
                required: '이메일을 입력해 주세요',
                pattern: {
                  value: regExp.email().test(JoinMembershipVm.email),
                  message: '올바른 이메일 형식으로 입력해 주세요.',
                },
              }}
            />
          }
        />
      </div>
      <div className={st.termsBox}>
        <CheckBox
          checked={JoinMembershipVm.agreeAll}
          onChange={(e) => JoinMembershipVm.setAgreeAll(e)}
          fontSize="15px"
          size="large"
        >
          이용약관에 모두 동의합니다.
        </CheckBox>
        <ul className={st.termsList}>
          {JoinMembershipVm.userRegistrationTerms.map((item, idx) => {
            return (
              <li key={idx}>
                <input type="hidden" name={`submittedTermRequests[${idx}].id`} value={item.id} />
                <CheckBox
                  name={`submittedTermRequests[${idx}].agreeYn`}
                  value={JoinMembershipVm.agreeYn(idx)}
                  checked={JoinMembershipVm.agreeYn(idx)}
                  onChange={(e) => JoinMembershipVm.setAgreeYn(e, idx)}
                  className={st.check}
                  checkIconClassName={st.checkIcon}
                  textClassName={st.radioText}
                >
                  <span className={st.notRequired}>{item.required === false && '[선택] '}</span>
                  {item.title}
                </CheckBox>
                {item.content && (
                  <TextBtn
                    className={st.textBtn}
                    onClick={() =>
                      JoinMembershipVm.termsPopup(
                        item.title,
                        <div
                          className={clsx(st.agreeContent, 'terms')}
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></div>,
                      )
                    }
                  >
                    상세보기
                  </TextBtn>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <SquareBtn
        type="submit"
        className={st.squareBtn}
        disabled={JoinMembershipVm.state.submitYn || !JoinMembershipVm.isSavePossible}
      >
        동의하고 시작하기
      </SquareBtn>
      <div className={st.likeBtnBox}>
        <Link to="/auth/login" className={st.likeBtn}>
          로그인
        </Link>
      </div>
    </form>
  ));
}
export default JoinMembership;
