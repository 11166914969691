import { useObserver } from 'mobx-react';
import RequestEmailCheckVm from './RequestEmailCheckVm';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import st from './RequestEmailCheck.module.scss';
import clsx from 'clsx';

import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
// import RetryBtn from '@standby/common-ui/components/atoms/Button/RetryBtn/RetryBtn';
import TextBtn from '@standby/common-ui/components/atoms/Button/TextBtn/TextBtn';
import Icon from '@standby/common-ui/components/atoms/Icon/Icon';

function RequestEmailCheck(props) {
  let location = useLocation();
  const emailType = queryString.parse(location.search).emailType;

  return useObserver(() => (
    <div className={st.RequestEmailCheckContent}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logo_standby.svg?1" />
        </a>
      </div>
      {emailType === 'joinMembership' && (
        <>
          <div className={st.mainTitle}>
            <span className={st.important}>이메일 인증 링크</span>가 1분 내로 도착할 예정이니
            <br />
            <span className={st.important}>메일함 확인</span>을 부탁드려요:)
          </div>
          <div className={st.email}>
            <div className={st.title}>발송한 이메일 주소</div>
            <InputText
              name="email"
              value={RequestEmailCheckVm.joinMembershipData.email}
              className={st.inputText}
              disabled
              fullSize
            />
          </div>
          <div className={st.explain}>
            <div>혹시 이메일이 오지 않았다면, 스팸함을 확인하세요.</div>
          </div>
          <button
            type="button"
            className={st.retryBtn}
            onClick={() => {
              RequestEmailCheckVm.sendJoinMembershipEmail();
            }}
            disabled={RequestEmailCheckVm.state.isSendingEmail}
          >
            이메일 다시 보내기
            <span className={st.icon} />
          </button>
          <TextBtn className={st.textBtn} onClick={() => RequestEmailCheckVm.sendOtherJoinMembershipEmail()}>
            다른 메일 주소로 보내기
          </TextBtn>
        </>
      )}
      {emailType === 'findPassword' && (
        <>
          <div className={st.mainTitle}>
            <span className={clsx(st.important, st.color)}>비밀번호 재설정 링크</span>를 메일로 보내드렸어요.
          </div>
          <div className={st.emailExplain}>
            혹시 이메일이 오지 않았다면, 스팸함을 확인하세요.
            <br />
            참! 가입되지 않은 이메일이라면 메일 발송이 안 되어요.
          </div>
          <div className={st.email}>
            <div className={st.title}>발송한 이메일 주소</div>
            <InputText
              name="email"
              value={RequestEmailCheckVm.findPasswordData.email}
              className={st.inputText}
              disabled
              fullSize
            />
          </div>
          <button
            type="button"
            className={st.retryBtn}
            onClick={() => {
              RequestEmailCheckVm.sendFindPasswordEmail();
            }}
            disabled={RequestEmailCheckVm.state.isSendingEmail}
          >
            이메일 다시 보내기
            <span className={st.icon} />
          </button>
          <TextBtn className={st.textBtn} onClick={() => RequestEmailCheckVm.sendOtherFindPasswordEmail()}>
            이전으로 돌아가기
          </TextBtn>
        </>
      )}
    </div>
  ));
}

export default RequestEmailCheck;
