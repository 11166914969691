import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useEffect } from 'react';
import vm from './InvitedVm';
import st from './Invited.module.scss';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import clsx from 'clsx';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import TextBtn from '@standby/common-ui/components/atoms/Button/TextBtn/TextBtn';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import ScrollArea from 'react-custom-scrollbars-2';

function Invited() {
  let location = useLocation();
  const token = queryString.parse(location.search).token;

  useEffect(() => {
    vm.load(token);
  }, []);

  return useObserver(() => (
    <form onSubmit={(e) => vm.submit(e, token)} className={st.formContent}>
      <ScrollArea
        className={st.scrollArea}
        renderThumbVertical={(props) => <div {...props} style={{ width: '3px', backgroundColor: '#D6D8DA' }} />}
      >
        <div className={st.contentView}>
          <div className={st.logoWrap}>
            <a href="https://standby.kr/" className={st.logo}>
              <img src="/images/logo_standby.svg?1" />
            </a>
          </div>
          <div className={st.mainTitle}>
            {vm.email}님<br />
            안녕하세요 :) 스탠바이 헬프미에 오신 것을 환영합니다.
          </div>
          <div className={st.inputRow}>
            <div className={st.title}>관리자로 초대받은 법인</div>
            <InputText name="text" value={vm.companyName} className={st.inputText} disabled fullSize />
          </div>
          <div className={clsx(st.inputRow, st.newPassword)}>
            <div className={st.title}>새 비밀번호</div>
            <InputText
              name="password"
              type="password"
              className={st.inputText}
              value={vm.password}
              onChange={vm.setPassword}
              fullSize
              errorText={
                <Error
                  name="password"
                  value={vm.password}
                  errorCase={{
                    required: '비밀번호를 입력해 주세요.',
                    pattern: {
                      value:
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                          vm.password,
                        ),
                      message: '올바른 비밀번호 형식으로 입력해 주세요.',
                    },
                  }}
                />
              }
            />
            <div className={st.passwordAlert}>
              <div>8글자 이상 입력</div>
              <div>영문, 숫자, 특수문자 각 1개 이상 사용</div>
            </div>
          </div>
          <div className={clsx(st.inputRow, st.newPasswordCheck)}>
            <div className={st.title}>새 비밀번호 확인</div>
            <InputText
              name="passwordCheck"
              type="password"
              className={st.inputText}
              value={vm.passwordCheck}
              onChange={vm.setPasswordCheck}
              fullSize
              errorText={
                <Error
                  name="passwordCheck"
                  value={vm.passwordCheck}
                  errorCase={{
                    required: '비밀번호 확인을 입력해 주세요.',
                    validate: {
                      func: vm.isSame(),
                      message: '동일한 비밀번호를 입력해주세요.',
                    },
                  }}
                />
              }
            />
          </div>
          <div className={clsx(st.inputRow, st.name)}>
            <div className={st.title}>이름</div>
            <InputText
              name="name"
              className={st.inputText}
              value={vm.name}
              onChange={vm.setName}
              fullSize
              errorText={
                <Error
                  name="name"
                  value={vm.name}
                  errorCase={{
                    required: '이름을 입력해 주세요.',
                    pattern: {
                      value: regExp.onlyKorEng().test(vm.name),
                      message: '한글 또는 영문으로 입력해 주세요.',
                    },
                  }}
                />
              }
            />
            <div className={st.nameExplain}>실명 사용을 추천드려요!</div>
          </div>
          <div className={clsx(st.inputRow, st.phoneNumber)}>
            <div className={st.title}>전화번호</div>
            <div className={st.phoneNumberBox}>
              <Select name="countryCode" value={vm.countryCode} disabled style={{ width: '85px' }}>
                <option value="">-</option>
                <option value="82">+82</option>
              </Select>
              <InputText
                name="phoneNumber"
                value={vm.phoneNumber}
                onChange={vm.setPhoneNumber}
                style={{ width: '367px' }}
                errorText={
                  <Error
                    name="phoneNumber"
                    value={vm.phoneNumber}
                    errorCase={{
                      required: '전화번호를 입력해 주세요.',
                      pattern: {
                        value: regExp.phoneNumber().test(vm.phoneNumber),
                        message: '올바른 전화번호를 입력해 주세요.',
                      },
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className={st.termsBox}>
            <CheckBox checked={vm.agreeAll} onChange={(e) => vm.setAgreeAll(e)} fontSize="15px">
              모두 동의합니다.
            </CheckBox>
            <div className={st.termsList}>
              {vm.userRegistrationTerms.map((item, idx) => {
                return (
                  <div className={st.checkBoxWrap} key={idx}>
                    <input type="hidden" name={`submittedTermRequests[${idx}].id`} value={item.id} />
                    <CheckBox
                      className={st.checkBox}
                      name={`submittedTermRequests[${idx}].agreeYn`}
                      value={vm.agreeYn(idx)}
                      checked={vm.agreeYn(idx) === true}
                      onChange={(e) => vm.setAgreeYn(e, idx)}
                      error={item.required && vm.state.errorCheckYn && vm.checkBoxError}
                    >
                      {item.required ? (
                        <span>[필수]</span>
                      ) : (
                        <span className={clsx(st.requiredFalse, vm.agreeYn(idx) ? st.active : null)}>[선택]</span>
                      )}{' '}
                      {item.title}
                    </CheckBox>
                    {item.content && (
                      <TextBtn
                        className={st.textBtn}
                        onClick={() =>
                          vm.termsPopup(
                            item.title,
                            <div
                              className={clsx(st.agreeContent, 'terms')}
                              dangerouslySetInnerHTML={{ __html: item.content }}
                            ></div>,
                          )
                        }
                      >
                        상세보기
                      </TextBtn>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {vm.state.errorCheckYn && vm.checkBoxError && (
            <ErrorText className={st.errorText}>필수 약관을 모두 동의해주세요.</ErrorText>
          )}
          <SquareBtn type="submit" className={st.squareBtn}>
            스탠바이 바로 시작하기
          </SquareBtn>
        </div>
      </ScrollArea>
    </form>
  ));
}
export default Invited;
