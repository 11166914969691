import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ChangeEmailVm from './ChangeEmailVm';
import regExp from '@common/module/regExp';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import st from './ChangeEmail.module.scss';
import queryString from 'query-string';

function ChangeEmail() {
  let location = useLocation();
  const token = queryString.parse(location.search).token;

  return useObserver(() => (
    <form onSubmit={(e) => ChangeEmailVm.submit(e, token)} className={st.ChangeEmailContent}>
      <div className={st.logoWrap}>
        <a href="https://standby.kr/" className={st.logo}>
          <img src="/images/logo_standby.svg?1" />
        </a>
      </div>
      <div className={st.mainTitle}>계정ID로 사용할 새로운 이메일을 입력하세요.</div>
      <div className={st.email}>
        <div className={st.title}>새로운 이메일</div>
        <InputText
          name="email"
          className={st.inputText}
          value={ChangeEmailVm.state.email}
          onChange={(e) => ChangeEmailVm.setEmail(e)}
          fullSize
          errorText={
            <Error
              name="email"
              value={ChangeEmailVm.state.email}
              errorCase={{
                required: '이메일을 입력해 주세요',
                pattern: {
                  value: regExp.email().test(ChangeEmailVm.state.email),
                  message: '올바른 이메일 형식으로 입력해 주세요.',
                },
              }}
            />
          }
        />
      </div>
      <SquareBtn type="submit" className={st.squareBtn} active={regExp.email().test(ChangeEmailVm.state.email)}>
        이메일 변경하기
      </SquareBtn>
    </form>
  ));
}
export default ChangeEmail;
