import { makeObservable, observable } from 'mobx';

import type {
  Companies,
  Company,
  CompanyRegisterStatus,
  CorpModelInterface,
  Menu,
  CompanyRegisterStatusError,
} from './corpModel.interface';

class corpModel implements CorpModelInterface {
  constructor() {
    makeObservable(this, {
      id: observable,
      company: observable,
      companies: observable,
      companyRegisterStatus: observable,
      stockholderListFileRegisterStatus: observable,
      menu: observable,
      alliancePopupShowYn: observable,
      acctFirmCode: observable,
      companyRegisterStatusError: observable,
    });
  }
  id: number | null = null;
  company: Company = {
    companyName: '',
    companyAddress: '',
    corpType: '',
    prefixedYn: null,
  };
  companies: Companies[] = [];
  companyRegisterStatus: CompanyRegisterStatus = null;
  stockholderListFileRegisterStatus = false;
  menu: Menu = {
    businessRegistration: {
      useYn: null,
      tagName: '',
    },
    articlesOfAssociationTagName: '',
    bookkeepingContractTagName: '',
    copyFileTagName: '',
    stockholderTagName: '',
    corporateTaxTagName: '',
    valueAddedTaxTagName: '',
    dashboardTagName: '',
    agendaTagName: '',
    internetAssociateTagName: '',
  };
  alliancePopupShowYn = false;
  acctFirmCode: 'JINPYUNG' | 'BARUN' | '' = '';
  companyRegisterStatusError: CompanyRegisterStatusError = {
    title: '',
    explain: '',
  };
}

export default new corpModel();
