import { useObserver } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { Route, Routes } from 'react-router-dom';

import AdditionalInformation from './AdditionalInformation/AdditionalInformation';
import st from './Auth.module.scss';
import AuthVm from './AuthVm';
import ChangeEmail from './ChangeEmail/ChangeEmail';
import ExpiredInvitedMembership from './ExpiredInvitedMembership/ExpiredInvitedMembership';
import FindId from './FindId/FindId';
import FindPassword from './FindPassword/FindPassword';
import InvitedJoinMembership from './Invited/Invited';
import JoinMembership from './JoinMembership/JoinMembership';
import Login from './Login/Login';
import LoginMobile from './Login/LoginMobile';
import RequestEmailCheck from './RequestEmailCheck/RequestEmailCheck';
import SetupNewPassword from './SetupNewPassword/SetupNewPassword';

function Auth() {
  return useObserver(() => (
    <Routes>
      {/* 비밀번호 찾기  */}
      <Route path="/findPassword" element={<DesktopWrap component={<FindPassword />} />} />
      {/* 이메일 확인 요청 */}
      <Route path="/requestemailCheck" element={<DesktopWrap component={<RequestEmailCheck />} />} />
      {/* 새로운 비밀번호 설정*/}
      <Route path="/setupNewPassword" element={<DesktopWrap component={<SetupNewPassword />} />} />
      {/* 회원가입 추가정보*/}
      <Route path="/additionalInformation" element={<DesktopWrap component={<AdditionalInformation />} />} />
      {/* 이메일변경  */}
      <Route path="/changeEmail" element={<DesktopWrap component={<ChangeEmail />} />} />
      {/* 로그인*/}
      <Route path="/login" element={isMobile ? <LoginMobile /> : <DesktopWrap component={<Login />} />} />
      {/* 회원가입*/}
      <Route path="/joinMembership" element={<DesktopWrap component={<JoinMembership />} />} />
      {/* 관리자 초대 회원가입 */}
      <Route path="/invitedMembership" element={<DesktopWrap scroll component={<InvitedJoinMembership />} />} />
      {/* 관리자 초대 가입링크 만료*/}
      <Route path="/expiredInvitedMembership" element={<DesktopWrap component={<ExpiredInvitedMembership />} />} />
      {/* 아이디 찾기*/}
      <Route path="/findId" element={<DesktopWrap component={<FindId />} />} />
    </Routes>
  ));
}

function DesktopWrap(props) {
  return useObserver(() => (
    <div className={st.wrap}>
      <div className={st.Auth}>
        <div className={st.AuthBox} style={AuthVm.changeHeight ? { height: '811px' } : { height: '727px' }}>
          <div
            className={st.authContent}
            style={{
              alignItems: props.scroll ? 'flex-start' : 'center',
              justifyContent: props.scroll ? 'flex-start' : 'center',
              paddingRight: props.scroll ? '10px' : '50px',
            }}
          >
            {props.component}
          </div>
          <div className={st.etcContent}>
            <div className={st.etcTitle}>
              번거로운 도장 날인, 우편발송
              <div className={st.etcBox}>최대한 줄이세요!</div>
            </div>
            <div className={st.lineBox}>
              <div className={st.line} />
            </div>
            <div className={st.etcText}>
              법인관리 스탠바이 헬프미에서
              <br />
              번거로운 업무를 최대한 줄이고 <span className={st.bold}>본업에 집중하세요</span>
            </div>
            <div className={st.etcExplain}>
              <img src="/images/loginEtc_0306.svg" className={st.etcImage} />
              <div className={st.explain}>
                <div className={st.explianTitle}>
                  <span className={st.color1}>이메일,</span> <span className={st.color2}>카톡</span>으로 처리하는{' '}
                  <span className={st.bold}>주주총회</span>
                </div>
                <ul>
                  <li>주주총회 소집통지 온라인 자동발송</li>
                  <li>온라인 소집절차 생략 동의</li>
                  <li>신주발행 통지 온라인 자동발송</li>
                </ul>
              </div>
            </div>
            {/* <img src='/images/loginEtc.svg' className={st.etcImage}/> */}
          </div>
        </div>
      </div>
    </div>
  ));
}

export default Auth;
