import { useObserver } from 'mobx-react';
import st from './Alliance.module.scss';
import vm from './AllianceVm';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

function Alliance() {
  let location = useLocation();
  const corp = queryString.parse(location.search).corp as string;

  return useObserver(() => (
    <>
      <div className={st.mainTitle}>스탠바이 회원사를 위한 특별한 혜택</div>
      <div className={st.mainContent}>
        <div className={st.topInfo}>
          <img className={st.logo} src="/images/AllianceContentLogo.svg?1" />
          <div className={st.text}>
            <span className={st.bold}>
              성장 속도 1위 법인 회원 플랫폼, <span className={st.color}>스탠바이</span>
            </span>
            의 바잉 파워로 회원사께 특별한 혜택을 드릴게요.
          </div>
        </div>
        <button className={st.mainInfo} type="button" onClick={() => vm.openUserSetting()}>
          <img src="/images/allianceMainInfo.svg" />
        </button>
        <ul className={st.allianceList}>
          <li>
            <a href={`/corp/internetAssociate?corp=${corp}`} target="_blank">
              <div className={st.content}>
                <div className={st.badge}>#기업용 인터넷&전화</div>
                <div className={st.title}>전국 유일! ‘기업용 인터넷&전화’ 캐시백 혜택</div>
                <div className={st.sub}>스탠바이 회원사 전용, 오직 스탠바이에서만 받을 수 있는 캐시백</div>
                <div className={st.link}>자세히 보기</div>
              </div>
              <img src="/images/allianceList_3.png" />
            </a>
          </li>
          <li>
            <a href={`/corp/awsCredit?corp=${corp}`} target="_blank">
              <div className={st.content}>
                <div className={st.badge}>#클라우드 지원금 270만 원</div>
                <div className={st.title}>AWS x 메가존 클라우드 지원금 270만 원</div>
                <div className={st.sub}>중복 지급 가능한 특별 지원금 지급!</div>
                <div className={st.link}>자세히 보기</div>
              </div>
              <img src="/images/allianceList_1.png?1" />
            </a>
          </li>
          <li>
            <a href={`/corp/accounting/bookKeepingContract?corp=${corp}`} target="_blank">
              <div className={st.content}>
                <div className={st.badge}>#기장계약</div>
                <div className={st.title}>세무기장 서비스</div>
                <div className={st.sub}>온라인으로 처리하는 세무기장</div>
                <div className={st.link}>자세히 보기</div>
              </div>
              <img src="/images/allianceList_2.png" />
            </a>
          </li>
        </ul>
      </div>
    </>
  ));
}
export default Alliance;
